import { Callout } from "@clipboard-health/ui-components";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import { SHIFT_DISCOVERY_HOME_HEALTH_PATH } from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";

interface HomeHealthCalloutProps {
  count?: number;
}

export function HomeHealthCallout(props: HomeHealthCalloutProps) {
  const { count } = props;

  const history = useHistory();
  const { baseNavigationPath } = useShiftModalsDataContext();

  if (!count || count <= 0) {
    return null;
  }

  return (
    <Callout
      aria-label="View Home Health"
      title={`Home Health ${count}`}
      iconType="house"
      sx={(theme) => ({
        backgroundColor: theme.palette.intent?.success.background,
        color: theme.palette.intent?.success.text,
      })}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALLOUT_VIEWED, { type: "homeHealth" });
        history.push(`${baseNavigationPath}/${SHIFT_DISCOVERY_HOME_HEALTH_PATH}`);
      }}
    />
  );
}
