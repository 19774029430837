import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack, type SxProps, type Theme } from "@mui/material";
import { type DateRange } from "@src/appV2/lib";
import { getDaylightSavingsTimeIndicator } from "@src/appV2/Shifts/MyShifts/utils";
import { type ReactNode } from "react";

import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";
import { formatHoursDurationCompact } from "../../utils/formatHoursDurationCompact";
import { WorkplaceNameWithDistance } from "./WorkplaceNameWithDistance";

interface ShiftSummaryInfoProps {
  dateRange: DateRange;
  deviceTimezone: string;
  durationInHours?: number;
  workplaceName: string;
  formattedDistance?: string;
  timezone?: string;
  sx?: SxProps<Theme>;
  /**
   * Optional children to render in the summary info
   * Used to render pills, above the summary info content
   */
  children?: ReactNode;
}

/**
 * Renders summary about the open shift
 * - Date of the shift
 * - Time of the shift with duration
 * - Workplace name and distance
 *
 * Also includes daylight savings time indicator if applicable
 */
export function ShiftCardSummaryInfo(props: ShiftSummaryInfoProps) {
  const {
    dateRange,
    timezone,
    deviceTimezone,
    durationInHours,
    workplaceName,
    formattedDistance,
    children,
    sx,
  } = props;

  const daylightSavingsTimeIndicator = getDaylightSavingsTimeIndicator({
    shift: { start: dateRange.startDate.toISOString(), end: dateRange.endDate.toISOString() },
    timeZone: timezone ?? "America/Los_Angeles",
  });

  return (
    <Stack spacing={3} sx={sx}>
      {children}

      <Text semibold variant="h6">
        {formatDayOfMonthWithDayName(dateRange.startDate, { timezone })}
      </Text>

      <Stack direction="row" sx={{ flexWrap: "wrap", columnGap: 3, alignItems: "baseline" }}>
        <TimeRangeLabel
          semibold
          dateRange={dateRange}
          timezone={timezone}
          deviceTimezone={deviceTimezone}
          variant="h6"
        />

        <Text variant="body2" sx={(theme) => ({ color: theme.palette.text.tertiary })}>
          {formatHoursDurationCompact(durationInHours ?? 0)}
        </Text>
      </Stack>

      {isDefined(daylightSavingsTimeIndicator) && (
        <Text variant="body2" sx={(theme) => ({ color: theme.palette.text.tertiary })}>
          {daylightSavingsTimeIndicator}
        </Text>
      )}

      <WorkplaceNameWithDistance
        workplaceName={workplaceName}
        formattedDistance={formattedDistance}
      />
    </Stack>
  );
}
