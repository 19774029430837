import { TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { CbhIcon } from "@clipboard-health/ui-components";
import { mergeSxProps } from "@clipboard-health/ui-react";
import { Stack, type StackProps } from "@mui/material";

export const ON_CALL_TIME_SLOT_GRADIENT_MAP: Record<TimeSlot, string> = {
  [TimeSlot.AM]: "radial-gradient(119.9% 100% at 0% 0%, #FFDBA6 10%, #F6E6CF 59.9%, #D7EFFC 100%)",
  [TimeSlot.PM]:
    "radial-gradient(112.18% 113.01% at 100% 100%, #FBD0A8 0%, #F8B4B5 56.12%, #9AA2FA 100%)",
  [TimeSlot.NOC]:
    "radial-gradient(96.11% 91.5% at 0% 8.5%, #BAC9F3 0%, #849DF0 66.86%, #5C6EB4 100%)",
};

interface OnCallIndicatorProps extends StackProps {
  timeSlot: TimeSlot;
}
export function OnCallIndicator(props: OnCallIndicatorProps) {
  const { timeSlot, sx } = props;
  const borderGradient = ON_CALL_TIME_SLOT_GRADIENT_MAP[timeSlot];

  return (
    <Stack
      sx={mergeSxProps(
        (theme) => ({
          alignItems: "center",
          padding: "18px",
          borderRadius: theme.borderRadius?.small ?? "inherit",
          border: "6px solid transparent",
          background: `linear-gradient(${theme.palette.background.quaternary}, ${theme.palette.background.quaternary}) padding-box, ${borderGradient} border-box`,
        }),
        sx
      )}
    >
      <CbhIcon type="old-phone" />
    </Stack>
  );
}
