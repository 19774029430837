import { startOfWeek } from "@clipboard-health/date-time";
import { isDefined } from "@clipboard-health/util-ts";
import { DEFAULT_TIMEZONE } from "@src/appV2/Shifts/Shift/constants";
import { differenceInSeconds, isEqual, parseISO, secondsInHour } from "date-fns";

import type {
  HoursRestrictionConflictData,
  ShiftDataForHourRestrictionConflict,
  WorkplaceDataForHourRestrictionConflict,
} from "./types";

const MINUTES_OF_OVERLAP_TO_ALLOW = 30;

function areShiftsConsecutive(
  shiftA: ShiftDataForHourRestrictionConflict,
  shiftB: ShiftDataForHourRestrictionConflict
) {
  if (
    Math.abs(differenceInSeconds(parseISO(shiftA.end), parseISO(shiftB.start))) <= secondsInHour
  ) {
    return true;
  }

  return (
    Math.abs(differenceInSeconds(parseISO(shiftA.start), parseISO(shiftB.end))) <= secondsInHour
  );
}

function areShiftsOverlappingByMinutes(
  shiftA: ShiftDataForHourRestrictionConflict,
  shiftB: ShiftDataForHourRestrictionConflict,
  minutes: number
): boolean {
  const minimumOverlapInSeconds = minutes * 60;
  const shiftAStart = parseISO(shiftA.start);
  const shiftAEnd = parseISO(shiftA.end);
  const shiftBStart = parseISO(shiftB.start);
  const shiftBEnd = parseISO(shiftB.end);

  const overlapStart = shiftAStart > shiftBStart ? shiftAStart : shiftBStart;
  const overlapEnd = shiftAEnd < shiftBEnd ? shiftAEnd : shiftBEnd;
  const overlapDuration = differenceInSeconds(overlapEnd, overlapStart);

  return overlapDuration >= minimumOverlapInSeconds;
}

function hasDoubleShiftConflict(
  shift: ShiftDataForHourRestrictionConflict,
  workplace: WorkplaceDataForHourRestrictionConflict,
  openShiftsAtSameFacility: ShiftDataForHourRestrictionConflict[]
) {
  const { preventDoubleShifts } = workplace;

  if (!preventDoubleShifts) {
    return false;
  }

  return openShiftsAtSameFacility.some(
    (openShift) =>
      areShiftsConsecutive(shift, openShift) &&
      !areShiftsOverlappingByMinutes(shift, openShift, MINUTES_OF_OVERLAP_TO_ALLOW + 1)
  );
}

function hasMaxWeeklyHoursConflict(
  shift: ShiftDataForHourRestrictionConflict,
  workplace: WorkplaceDataForHourRestrictionConflict,
  openShiftsAtSameFacility: ShiftDataForHourRestrictionConflict[],
  bookedShiftsAtSameFacility: ShiftDataForHourRestrictionConflict[]
) {
  const { maxAllowedWorkHoursPerWeek, timezone } = workplace;
  const { end, start } = shift;

  if (!maxAllowedWorkHoursPerWeek) {
    return false;
  }

  const workplaceTimezone = timezone ?? DEFAULT_TIMEZONE;

  const shiftDurationHours = differenceInSeconds(parseISO(end), parseISO(start)) / secondsInHour;

  const bookedShiftsSameWeek = bookedShiftsAtSameFacility.filter((shift) => {
    const { start: shiftStart } = shift;
    return isEqual(
      startOfWeek(parseISO(shiftStart), { timezone: workplaceTimezone }),
      startOfWeek(parseISO(start), { timezone: workplaceTimezone })
    );
  });

  const bookedHoursSameWeek = bookedShiftsSameWeek.reduce((bookedHours, shift) => {
    const { start: shiftStart, end: shiftEnd } = shift;
    return (
      bookedHours + differenceInSeconds(parseISO(shiftEnd), parseISO(shiftStart)) / secondsInHour
    );
  }, 0);

  const remainingHoursThisWeekAfterBooking =
    maxAllowedWorkHoursPerWeek - bookedHoursSameWeek - shiftDurationHours;

  const openShiftsSameWeek = openShiftsAtSameFacility.filter((openShift) => {
    const { start: openShiftStart } = openShift;
    return isEqual(
      startOfWeek(parseISO(openShiftStart), { timezone: workplaceTimezone }),
      startOfWeek(parseISO(start), { timezone: workplaceTimezone })
    );
  });

  return openShiftsSameWeek.some((openShift) => {
    const { start: openShiftStart, end: openShiftEnd } = openShift;
    const openShiftDurationHours =
      differenceInSeconds(parseISO(openShiftEnd), parseISO(openShiftStart)) / secondsInHour;
    return openShiftDurationHours > remainingHoursThisWeekAfterBooking;
  });
}

function hasConsecutiveHoursConflict(
  shift: ShiftDataForHourRestrictionConflict,
  workplace: WorkplaceDataForHourRestrictionConflict,
  openShiftsAtSameFacility: ShiftDataForHourRestrictionConflict[] = [],
  bookedShiftsAtSameFacility: ShiftDataForHourRestrictionConflict[] = []
) {
  const { preventDoubleShifts, maxAllowedWorkConsecutiveHours } = workplace;
  const { end, start } = shift;

  if (preventDoubleShifts) {
    return false;
  }

  if (!isDefined(maxAllowedWorkConsecutiveHours)) {
    return false;
  }

  const shiftDurationHours = differenceInSeconds(parseISO(end), parseISO(start)) / secondsInHour;

  const consecutiveShifts = [...openShiftsAtSameFacility, ...bookedShiftsAtSameFacility].filter(
    (s) => areShiftsConsecutive(shift, s)
  );

  return consecutiveShifts.some((consecutiveShift) => {
    const { start: openShiftStart, end: openShiftEnd } = consecutiveShift;
    const openShiftDurationHours =
      differenceInSeconds(parseISO(openShiftEnd), parseISO(openShiftStart)) / secondsInHour;

    if (shiftDurationHours + openShiftDurationHours > maxAllowedWorkConsecutiveHours) {
      return true;
    }

    return openShiftDurationHours > maxAllowedWorkConsecutiveHours;
  });
}

export function shiftHasHourRestrictionConflict(
  shift: ShiftDataForHourRestrictionConflict,
  workplace: WorkplaceDataForHourRestrictionConflict,
  hoursRestrictionConflictData: HoursRestrictionConflictData
) {
  const { openShiftsAtSameFacility, bookedShiftsAtSameFacility } = hoursRestrictionConflictData;

  const openShiftsExcludingThisShift = openShiftsAtSameFacility.filter((s) => s.id !== shift.id);

  const { preventDoubleShifts } = workplace;

  if (
    preventDoubleShifts &&
    hasDoubleShiftConflict(shift, workplace, openShiftsExcludingThisShift)
  ) {
    return true;
  }

  if (
    !preventDoubleShifts &&
    hasConsecutiveHoursConflict(
      shift,
      workplace,
      openShiftsExcludingThisShift,
      bookedShiftsAtSameFacility
    )
  ) {
    return true;
  }

  return hasMaxWeeklyHoursConflict(
    shift,
    workplace,
    openShiftsExcludingThisShift,
    bookedShiftsAtSameFacility
  );
}
