import { Stack } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { type ItemProps } from "react-virtuoso";

import type { VirtualShiftListGroupHeader } from "./useVirtualShiftListContext/types";

interface ShiftListStickyHeaderProps extends Omit<ItemProps<VirtualShiftListGroupHeader>, "item"> {
  scrollParentRef?: React.RefObject<HTMLDivElement>;
}

/**
 * Detect when the list header is stuck to the top of the list
 * Source: https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
 */
function useIsStuck(ref: React.RefObject<HTMLDivElement>) {
  const [isStuck, setIsStuck] = useState(false);

  const debouncedSetIsStuck = useMemo(
    () => debounce(setIsStuck, 100, { leading: true }),
    [setIsStuck]
  );

  useEffect(() => {
    let observer: IntersectionObserver;
    if (ref.current) {
      observer = new IntersectionObserver(
        ([event]) => {
          debouncedSetIsStuck(event.intersectionRatio < 1);
        },
        { threshold: [1] }
      );

      observer.observe(ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [debouncedSetIsStuck, ref]);

  return isStuck;
}

/**
 * Used to enable sticky header styles only when the user has actually scrolled
 */
function useIsParentScrolled(scrollParentRef?: React.RefObject<HTMLDivElement>) {
  const [isParentScrolled, setIsParentScrolled] = useState(false);

  const debouncedSetIsParentScrolled = useMemo(
    () => debounce(setIsParentScrolled, 100, { leading: true }),
    [setIsParentScrolled]
  );

  useEffect(() => {
    const scrollParent = scrollParentRef?.current;

    const handleScroll = () => {
      const isScrolled = (scrollParent?.scrollTop ?? 0) > 0;

      if (isScrolled && !isParentScrolled) {
        debouncedSetIsParentScrolled(true);
        return;
      }

      if (!isScrolled && isParentScrolled) {
        debouncedSetIsParentScrolled(false);
      }
    };

    scrollParent?.addEventListener("scroll", handleScroll);

    return () => {
      scrollParent?.removeEventListener("scroll", handleScroll);
    };
  }, [isParentScrolled, scrollParentRef, debouncedSetIsParentScrolled]);

  return isParentScrolled;
}

export function ShiftListStickyHeader(props: ShiftListStickyHeaderProps) {
  const { scrollParentRef, ...rest } = props;

  const ref = useRef<HTMLDivElement>(null);
  const isStuck = useIsStuck(ref);
  const isParentScrolled = useIsParentScrolled(scrollParentRef);

  /**
   * Pages using StickyScrollPageHeader have a padding top that needs to be compensated for.
   * https://github.com/ClipboardHealth/cbh-core/blob/6e4af221565c7e96201f5df9bb717f90d25c9720/packages/ui-components/src/components/StickyScrollPageHeader/index.tsx#L106
   */
  const parentPaddingTop = scrollParentRef?.current?.style.paddingTop ?? 0;

  return (
    <Stack
      {...rest}
      ref={ref}
      pt={5}
      sx={(theme) => ({
        // Compensate for the parent's padding top.
        // `-1px` is necessary for detecting when the header is stuck.
        top: parentPaddingTop ? `calc(-1px - ${parentPaddingTop})` : "-1px",
        zIndex: 1,
        position: "sticky",
        // Using ::before to avoid layout shift when the header is stuck
        "::before": {
          content: '""',
          display: "block",
          backgroundColor: theme.palette.background.tertiary,
          position: "absolute",
          top: 0,
          zIndex: -1,
          boxSizing: "content-box",
          height: "100%",
          paddingBottom: theme.spacing(5),
          // Compensate for the page's horizontal padding
          width: `calc(100% + ${theme.spacing(5)} + ${theme.spacing(5)})`,
          left: `-${theme.spacing(5)}`,
          right: `-${theme.spacing(5)}`,
          boxShadow: `inset 0 -1px 0 0 ${
            isStuck && isParentScrolled ? theme.palette.border?.primary : "transparent"
          }`,
        },
      })}
    />
  );
}
