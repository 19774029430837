import { type FeatureFlagVariants } from "@src/appV2/FeatureFlags";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

import { DEFAULT_MISSED_SHIFTS_CONFIG } from "./types";

export function useMissedShiftsConfig(): FeatureFlagVariants[CbhFeatureFlag.MISSED_SHIFTS_CONFIGURATION] {
  return useCbhFlag(CbhFeatureFlag.MISSED_SHIFTS_CONFIGURATION, {
    defaultValue: DEFAULT_MISSED_SHIFTS_CONFIG,
  });
}
