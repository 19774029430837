import { Title } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";

interface ShiftListDateHeaderProps {
  label: string;
  totalShiftsCount: number;
}

export function ShiftListDateHeader(props: ShiftListDateHeaderProps) {
  const { label, totalShiftsCount } = props;

  return (
    <Box aria-label={label} sx={{ paddingLeft: 3 }}>
      <Title
        semibold
        variant="h5"
        component="h2"
        sx={(theme) => ({
          zIndex: 1,
          color: totalShiftsCount === 0 ? theme.palette.text.disabled : theme.palette.text.primary,
        })}
      >
        {label}
      </Title>
    </Box>
  );
}
