import { Box } from "@mui/material";

import {
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM,
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT,
} from "../../ShiftDiscovery/SearchModeSwitcher";
import { LoadingMoreIndicator } from "./LoadingMoreIndicator";

interface ShiftListFooterProps {
  isLoadingMore?: boolean;
  children?: React.ReactNode;
}

export function ShiftListFooter(props: ShiftListFooterProps) {
  const { isLoadingMore, children } = props;
  return (
    <Box
      sx={(theme) => ({
        paddingTop: 4,
        paddingBottom: `calc(
        ${theme.spacing(3)} +
        ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT)} +
        ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM)}
  )`,
      })}
    >
      {children}
      {isLoadingMore ? <LoadingMoreIndicator sx={{ paddingBottom: 4 }} /> : undefined}
    </Box>
  );
}
