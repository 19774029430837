import { type TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { Pill, TextSkeleton } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { PayInfoLabel } from "@src/appV2/redesign/Pay/PayInfoLabel";
import { formatTime } from "@src/appV2/redesign/utils/formatTime";
import { addHours, differenceInHours, isAfter } from "date-fns";

import { BookingCardContentWrapper } from "../../CardContentWrapper";
import { OnCallIndicator } from "../../OnCallIndicator";
import { ShiftCardPillsWrapper } from "../../PillsWrapper";
import { ShiftQualificationPill } from "../../QualificationPill";
import { BookingCardSummaryWrapper } from "../../SummaryWrapper";
import { ShiftCardSummaryInfo } from "../CardSummaryInfo";

interface OnCallShiftCardContentProps {
  timeSlot: TimeSlot;
  startDate: Date;
  endDate: Date;
  isLoading?: boolean;
  offerUnavailable?: boolean;
  hourlyPay?: string;
  isBooked?: boolean;
  qualificationText: string;
  workplaceTimezone?: string;
  deviceTimezone: string;
}

export function OnCallShiftCardContent(props: OnCallShiftCardContentProps) {
  const {
    timeSlot,
    isLoading,
    offerUnavailable,
    hourlyPay,
    isBooked,
    qualificationText,
    startDate,
    endDate,
    workplaceTimezone,
    deviceTimezone,
  } = props;
  // we want to trim on-call shifts so we only show the first three hours at most
  const maxEndDateToShow = addHours(startDate, 3);
  const clampedEndDate = isAfter(endDate, maxEndDateToShow) ? maxEndDateToShow : endDate;
  const clampedDuration = differenceInHours(clampedEndDate, startDate);

  return (
    <BookingCardContentWrapper>
      <BookingCardSummaryWrapper>
        <OnCallIndicator timeSlot={timeSlot} />
        <Stack spacing={1}>
          <ShiftCardSummaryInfo
            dateRange={{ startDate, endDate: clampedEndDate }}
            timezone={workplaceTimezone}
            deviceTimezone={deviceTimezone}
            durationInHours={clampedDuration}
            workplaceName="Workplace TBD"
          >
            <ShiftCardPillsWrapper>
              <Pill
                iconType={isBooked ? "checkmark" : undefined}
                size="small"
                variant={isBooked ? "filled" : "outlined"}
                color={(theme) =>
                  isBooked ? theme.palette.intent?.success.text : theme.palette.intent?.neutral.text
                }
                backgroundColor={(theme) =>
                  isBooked
                    ? theme.palette.intent?.success.background
                    : theme.palette.background.tertiary
                }
                iconColor={(theme) =>
                  isBooked ? theme.palette.intent?.success.icon : theme.palette.intent?.neutral.icon
                }
                sx={{ width: "fit-content" }}
                label={`On call at ${formatTime(startDate, workplaceTimezone)}`}
              />
            </ShiftCardPillsWrapper>
          </ShiftCardSummaryInfo>
          <Text variant="caption" color={(theme) => theme.palette.text.secondary}>
            Duration approx. 8 hours
          </Text>
        </Stack>
      </BookingCardSummaryWrapper>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ShiftQualificationPill qualificationText={qualificationText} />
        {isLoading && <TextSkeleton width="25%" />}
        {!isLoading && offerUnavailable && (
          <Pill disabled label="Shift is unavailable" iconType="info" />
        )}
        {isDefined(hourlyPay) && (
          <PayInfoLabel totalPay={undefined} hourlyPay={hourlyPay} isLoading={isLoading} />
        )}
      </Stack>
    </BookingCardContentWrapper>
  );
}
