import { type CbhFeatureFlag, type FeatureFlagVariants } from "@src/appV2/FeatureFlags";

export const DEFAULT_MISSED_SHIFTS_CONFIG: FeatureFlagVariants[CbhFeatureFlag.MISSED_SHIFTS_CONFIGURATION] =
  {
    enabled: false,
    maxResultsToShow: 0,
    minimumPayPercentile: 100,
  };

export interface MissedShiftData {
  id: string;
  hourlyRate: number;
  distanceFromHome: number;
  theirScore: number;
  yourScore: number;
}
