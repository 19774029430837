import { Stack } from "@mui/material";
import { type ItemProps } from "react-virtuoso";

import type { OpenShiftListItem } from "./types";

interface ShiftListItemWrapperProps extends Omit<ItemProps<OpenShiftListItem>, "item"> {}

/**
 * A wrapper for the item component for react-virtuoso's list.
 * Uses padding to separate items instead of margin because
 * Virtuoso recommends not using margins to avoid miscalculations
 * in their content measuring mechanism: https://virtuoso.dev/#caveats
 */
export function ShiftListItemWrapper(props: ShiftListItemWrapperProps) {
  return <Stack {...props} pt={5} />;
}
