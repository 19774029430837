import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

import { shiftHasHourRestrictionConflict } from "../shiftHasHourRestrictionConflict";
import type {
  ShiftDataForHourRestrictionConflict,
  WorkplaceDataForHourRestrictionConflict,
} from "../types";
import { useHourRestrictionConflictData } from "./useHourRestrictionConflictData";

export interface UseHasHourRestrictionConflictProps {
  shift?: ShiftDataForHourRestrictionConflict;
  workplace?: WorkplaceDataForHourRestrictionConflict;
}

export function useHasHourRestrictionConflict(props: UseHasHourRestrictionConflictProps) {
  const { shift, workplace } = props;

  const killSwitch = useCbhFlag(CbhFeatureFlag.HIDE_HOUR_RESTRICTION_WARNING_IF_NO_CONFLICTS, {
    defaultValue: true,
  });

  const enabled = !killSwitch && isDefined(shift);

  const { data: hoursRestrictionConflictData, isLoading } = useHourRestrictionConflictData(
    {
      shiftStart: shift?.start,
      workplaceId: workplace?.id,
    },
    { enabled }
  );

  if (killSwitch) {
    return {
      hasHoursRestrictionConflict: true,
      isLoading: false,
    };
  }

  const hasHoursRestrictionConflict =
    isDefined(shift) &&
    isDefined(workplace) &&
    isDefined(hoursRestrictionConflictData) &&
    shiftHasHourRestrictionConflict(shift, workplace, hoursRestrictionConflictData);

  return {
    hasHoursRestrictionConflict,
    isLoading: isLoading && enabled,
  };
}
