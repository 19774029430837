import { Pill } from "@clipboard-health/ui-components";
import { Stack } from "@mui/material";

import { ShiftQualificationPill } from "../QualificationPill";
import { ShiftPayInfoLabel } from "../ShiftPayInfoLabel";

interface OpenShiftCardFooterProps {
  offerPay?: number;
  finalPay?: number;
  qualificationText: string;
  originalAmount?: number;
  offerUnavailable?: boolean;
  isLoadingOffer?: boolean;
  isLoadingQualification?: boolean;
}

/**
 * Renders qualification pill and pay info label
 */
export function OpenShiftCardFooter(props: OpenShiftCardFooterProps) {
  const {
    offerPay,
    finalPay,
    originalAmount,
    offerUnavailable,
    qualificationText,
    isLoadingOffer,
    isLoadingQualification,
  } = props;

  return (
    <Stack direction="row" justifyContent="space-between" spacing={3}>
      <ShiftQualificationPill
        qualificationText={qualificationText}
        isLoading={isLoadingQualification}
      />

      {offerUnavailable ? (
        <Pill disabled label="Shift is unavailable" iconType="info" />
      ) : (
        <ShiftPayInfoLabel
          isLoading={isLoadingOffer}
          offerPay={offerPay}
          finalPay={finalPay}
          originalAmount={originalAmount}
        />
      )}
    </Stack>
  );
}
