import "swiper/css";

import { ShiftStageEnum } from "@clipboard-health/contract-worker-app-bff";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { useClipboardScoreRollout } from "@src/appV2/AttendanceScore/hooks/featureFlags";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { useShiftDiscoveryUserFiltersContext } from "@src/appV2/redesign/ShiftDiscovery/Filters/useUserFiltersContext";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useWorkerGeoLocation } from "@src/appV2/Worker/useWorkerGeoLocation";
import { addDays, endOfDay, parseISO, startOfDay } from "date-fns";
import { Swiper, SwiperSlide } from "swiper/react";

import { useSearchShifts } from "../useSearchShifts";
import { useMissedShiftsConfig } from "./useMissedShiftsConfig";

// eslint-disable-next-line sonarjs/cognitive-complexity
export function MissedShifts() {
  const config = useMissedShiftsConfig();
  const workerGeoLocation = useWorkerGeoLocation();
  const worker = useDefinedWorker();
  const {
    dates: dateStrings,
    distance,
    shiftTimeSlots,
    licenses,
    availableLicenses,
  } = useShiftDiscoveryUserFiltersContext();
  const isClipboardScoreEnabled = useClipboardScoreRollout();

  // Get the current worker's clipboardScore
  const { data: attendanceScoreProfile } = useGetAttendanceScoreProfile(worker.userId);
  const yourClipboardScore = isClipboardScoreEnabled
    ? attendanceScoreProfile?.clipboardScore
    : attendanceScoreProfile?.score;

  // Handle date filters
  const hasDateFilters = dateStrings.length > 0;
  const hasWorkerGeo =
    isDefined(workerGeoLocation?.latitude) && isDefined(workerGeoLocation?.longitude);
  const startDate = hasDateFilters
    ? startOfDay(parseISO(dateStrings[0])).toISOString()
    : startOfDay(new Date()).toISOString();
  const endDate = hasDateFilters
    ? endOfDay(parseISO(dateStrings.at(-1) ?? dateStrings[0])).toISOString()
    : endOfDay(addDays(new Date(), 30)).toISOString();

  const licensesToUse = licenses.length > 0 ? licenses : availableLicenses;

  const {
    data: searchShiftsData,
    isLoading,
    isError,
  } = useSearchShifts(
    {
      filter: {
        minimumPayPercentile: config.minimumPayPercentile ?? 100,
        stage: ShiftStageEnum.MISSED,
        startAt: {
          gte: startDate,
          lte: endDate,
        },
        types: shiftTimeSlots,
        worker: {
          stage: [],
          id: worker.userId,
          qualifications: licensesToUse,
          geo: hasWorkerGeo
            ? {
                latitude: workerGeoLocation.latitude,
                longitude: workerGeoLocation.longitude,
                distance,
              }
            : undefined,
        },
      },
      include: ["bookedWorker"],
      page: {
        size: config.maxResultsToShow ?? 10,
      },
    },
    {
      enabled: isDefined(config.enabled) && config.enabled,
      retry: false,
      useErrorBoundary: false,
      userErrorMessage: "",
    }
  );

  if (isLoading || !config.enabled || isError) {
    return null;
  }

  const { data: shifts = [], included = [] } = searchShiftsData ?? {};
  if (shifts.length === 0) {
    return null;
  }

  const missedShifts = shifts
    .map((shift) => {
      const workerItem = included.find(
        (item) => item?.type === "worker" && item?.id === shift.relationships.worker?.data.id
      );

      if (!workerItem) {
        return null;
      }

      const clipboardScore =
        typeof workerItem.attributes === "object" &&
        workerItem.attributes !== null &&
        "clipboardScore" in workerItem.attributes
          ? Number(workerItem.attributes.clipboardScore)
          : 0;

      return {
        id: shift.id,
        hourlyPay: shift.attributes.hourlyPay,
        bookedScore: clipboardScore,
        distance: shift.attributes.distance,
      };
    })
    .filter((shift) => shift !== null);

  if (missedShifts.length === 0) {
    return null;
  }

  const onlyOneShift = missedShifts.length === 1;

  return (
    <Stack
      spacing={1}
      padding={3}
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        borderRadius: (theme) => theme.borderRadius?.xSmall,
      }}
    >
      <Text bold variant="h6" align="center">
        Higher Score = More Priority Access
      </Text>
      <Text
        variant="body2"
        align="center"
        sx={(theme) => ({ color: theme.palette.text.secondary, mb: 1 })}
      >
        Shifts booked by accounts with high Clipboard Scores:
      </Text>

      <Box sx={{ position: "relative" }}>
        <Swiper
          spaceBetween={8}
          slidesPerView={onlyOneShift ? 1 : 1.1}
          centeredSlides={onlyOneShift}
          style={{ paddingRight: onlyOneShift ? "0" : "8px" }}
        >
          {missedShifts.map((shift) => (
            <SwiperSlide key={shift.id}>
              <Box
                sx={(theme) => ({
                  border: `${theme.borderWidth.thin} solid ${theme.palette.primary.main}`,
                  borderRadius: theme.borderRadius?.xSmall,
                  padding: 3,
                  backgroundColor: theme.palette.common.white,
                })}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 8,
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Stack spacing={1}>
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                      <Text semibold variant="h5">
                        {formatDollarsAsUsd(shift.hourlyPay ?? 0, {
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                      <Text variant="body2" sx={{ ml: 1 }}>
                        /hr
                      </Text>
                    </Box>
                    <Text variant="body2">{Number(shift.distance).toFixed(1)} mi from home</Text>
                  </Stack>

                  <Stack spacing={1} alignItems="flex-start">
                    <Text variant="body2">
                      Their score:{" "}
                      <Box component="span" sx={{ fontWeight: 600 }}>
                        {shift.bookedScore}
                      </Box>
                    </Text>
                    <Text variant="body2">
                      Your score:{" "}
                      <Box component="span" sx={{ fontWeight: 600 }}>
                        {yourClipboardScore ?? 0}
                      </Box>
                    </Text>
                  </Stack>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Stack>
  );
}
